<template>
    <defaultSec :title="'梯队管理'">
        <keep-alive>
        </keep-alive>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="dataList" border size="mini" v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="名称" prop="echelonName" width="190"></el-table-column>
                <el-table-column label="绩效系数" prop="performanceCoefficient" width="120"></el-table-column>
                <el-table-column label="目标GMV" prop="targetGmv" width="120"></el-table-column>
                <el-table-column label="实际gmv" prop="gmv" width="120"></el-table-column>
                <el-table-column label="状态" prop="status" width="80">
                    <template slot-scope="scope">
                        <el-tag size="medium" type="" v-if="scope.row.status==0">未结算</el-tag>
                        <el-tag size="medium" type="danger" v-if="scope.row.status==1">已结算</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="结算时间" prop="importGmvTime"></el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="150"></el-table-column>
                <el-table-column label="操作" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="warning " size="mini"
                                       @click="toDetail(scope.row)">查看详情

                            </el-button>
                            <el-button type="primary " size="mini"
                                       @click="getMemberList(scope.row)">梯队成员

                            </el-button>
                            <el-button type="danger " v-if="scope.row.status==0" size="mini"
                                       @click="computeAwardByMonth(scope.row.echelonId)">结算

                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
        <el-dialog title="设置梯队成员" :visible.sync="showDialog" center>
            <el-table :data="memberList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="名称" prop="name" width="200"></el-table-column>
                <el-table-column label="角色" prop="roleTypeName" width="140"></el-table-column>
                <el-table-column label="师傅" prop="masterName">
                    <template slot-scope="scope">
                        <el-select style="width: 100%" size="small" :disabled="scope.row.roleTypeName=='-'" clearable v-model="scope.row.masterQyWeixinUserid"
                                   placeholder="请选择师傅" @change="handleMasterChange(scope.row)">
                            <el-option v-for="item in customerList" :value="item.id" :label="item.name"></el-option>
                        </el-select>
                        <el-button type="primary " size="mini" :disabled="scope.row.roleTypeName=='-'"
                                   @click="saveMaster(scope.row)">保存
                            </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="warning " size="mini" :disabled="!scope.row.canSetTeamMember"
                                       @click="setTeamMember(scope.row,2)">设置队员

                            </el-button>
                            <el-button type="primary " size="mini" :disabled="!scope.row.canSetLeader"
                                       @click="setTeamMember(scope.row,1)">设置队长

                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1
                },
                dataList: [],
                memberList: [],
                loading: false,
                customerList: [],
                showDialog: false,
                total: 0
            }
        },
        components: {
            defaultSec, tablePagination
        },
        mounted(){
            this.initList()
            this.initCustomerList()
        },
        methods: {
            initList() {
                this.dataList = []
                this.loading = true
                this.$apiGet('lechun-cms/echelon/getDataList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.dataList = res.list
                    this.loading = false
                })
            },
            getMemberList(row) {
                this.memberList = []
                this.showDialog = true
                this.$apiGet('lechun-cms/echelon/getEchelonMemberList', {echelonId: row.echelonId}).then(res => {
                    console.log(res)
                    this.memberList = res
                })
            },
            //企微客服列表
            initCustomerList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.customerList = res
                })
            },
            handleMasterChange(row){
                console.log('师傅条件选择改变后:'+row)
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            toDetail(row){
                this.$router.push({
                    name: 'echelonDetail',
                    params: {echelonId: row.echelonId}
                })
            },
            computeAwardByMonth(echelonId){
                this.loading = true
                this.$apiGet('lechun-cms/echelon/computeAwardByMonth', {
                    echelonId: echelonId
                }).then(res => {
                    console.log(res)
                    this.loading = false
                    this.initList()
                })
            },
            saveMaster(row){
                //保存师傅
                this.$apiGet('lechun-cms/echelon/setMaster', {
                    echelonDetailedId: row.echelonDetailedId,
                    masterQyWeixinUserid: row.masterQyWeixinUserid
                }).then(res => {
                    console.log(res)
                    this.showDialog = false
                    this.initList()
                })
            },
            setTeamMember(row, roleType){
                //设置梯队角色
                this.$apiGet('lechun-cms/echelon/saveEchelonDetailed', {
                    echelonId: row.echelonId,
                    qyWeixinUserid: row.qyWeixinUserid,
                    roleType: roleType
                }).then(res => {
                    console.log(res)
                    this.showDialog = false
                    this.initList()
                })
            }
        }
    }
</script>


<style scoped>
    .expand-title {
        width: 70px;
        color: #444548;
        line-height: 30px;
        text-align: right;
        margin-right: 10px;
        font-weight: 600;
    }

    .expand-flex {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
</style>